.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-black);

    padding: 1rem 2rem;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: fit-content;
}

.app__navbar-logo img {
    width: 350px;
}

.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;

    list-style: none;
}

.app__navbar-links li{
    margin: 0 1rem;
    cursor: pointer;
}

.app__navbar-links li:hover{
   color : var(--color-grey)
}

.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.app__navbar-login a{
    margin: 0 1rem;
    text-decoration: none;
    transition: 0.5 ease;
}

.app__navbar-login a:hover{
    border-bottom: 1px solid var(--color-golden);
}

.app__navbar-login div {
    width: 1px;
    height: 30px;
    background-color: var(--color-grey);
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition:  0.5s ease;

    flex-direction: column;
    z-index: 5;
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size:  2rem;
    text-align: center;
    font-family:'Courier New', Courier, monospace;

}

.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);

}

@media screen and (max-width: 2000px) {
    .app__navbar-logo img{
        width: 210;
    }
}

@media screen and (max-width: 1150px) {
    .app__navbar-links{
        display: none;
    }
    .app__navbar-smallscreen {
        display:flex;

    }
}

@media screen and (max-width: 650px) {
    .app__navbar {
     padding: 1rem;
    }
    .app__navbar-login {
        display: none;
    }
    .app__navbar-logo img{
        width: 110;
    }
}
